import React from 'react'
import styled from 'styled-components'

// import SEO from '../components/common/SEO'
// import Page from '../components/common/Page.layout'
import { H1, H3, P, Link } from '../components/UI'

const Wrap = styled.div`
	min-height: calc(100vh - ${props => props.theme.sizes.header.mobile.px});
	display: flex;
	align-items: center;
	${props => props.theme.media.sdesk} {
		min-height: calc(100vh - ${props => props.theme.sizes.header.sdesk.px});
	}
	.content {
		padding: 1em;
		${props => props.theme.media.sdesk} {
			padding: 2em;
		}
	}
`

const NotFoundPage = () => (
	// <Page
	// 	pageContext={{ breadcrumbs: [{ title: 'Page not found' }] }}
	// 	pageData={{ pageTitle: '404: Not found' }}
	// >
	// 	<SEO pageData={{ title: '404: Not Found' }} />
	// 	<Wrap>
	// 		<div className='content'>
	// 			<H1>404: Not found</H1>
	// 			<H3>We were unable to locate a page at this path...</H3>
	// 			<P>
	// 				If you have reason to believe you have reached this in error please{' '}
	// 				<Link to='/contact'>let us know</Link>.
	// 			</P>
	// 			<P>Thank you, and we apologize for any inconvenience.</P>
	// 		</div>
	// 	</Wrap>
	// </Page>
	<Wrap>
		<div className='content'>
			<H1>404: Not found</H1>
			<H3>We were unable to locate a page at this path...</H3>
			<P>
				If you have reason to believe you have reached this in error please{' '}
				<Link to='/contact'>let us know</Link>.
			</P>
			<P>Thank you, and we apologize for any inconvenience.</P>
		</div>
	</Wrap>
)

export default NotFoundPage
